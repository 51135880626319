body {
  margin: 0;
  padding: 0;
  border: 0;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

.App {
  text-align: center;
}


.link:visited{
  color: blue;
}